import { AccountsApiClient, AlarmsApiClient, ContactsApiClient, IntegrationsApiClient, EvaluationsApiClient, RulesApiClient, SelfAccountApiClient, EntryPointArgsApiClient } from '@/repositories';

export interface IContainer {
    accountsClient: AccountsApiClient;
    evaluationsClient: EvaluationsApiClient;
    rulesClient: RulesApiClient;
    contactsClient: ContactsApiClient;
    integrationsClient: IntegrationsApiClient;
    alarmsClient: AlarmsApiClient;
    alarmOptionsClient: RulesApiClient;
    selfClient: SelfAccountApiClient;
    entryPointArgsClient: EntryPointArgsApiClient;
}

export default class Container implements IContainer {
    get accountsClient(): AccountsApiClient {
        return new AccountsApiClient();
    }
    get evaluationsClient(): EvaluationsApiClient {
        return new EvaluationsApiClient();
    }
    get rulesClient(): RulesApiClient {
        return new RulesApiClient();
    }
    get contactsClient(): ContactsApiClient {
        return new ContactsApiClient();
    }
    get integrationsClient(): IntegrationsApiClient {
        return new IntegrationsApiClient();
    }
    get alarmsClient(): AlarmsApiClient {
        return new AlarmsApiClient();
    }
    get alarmOptionsClient(): RulesApiClient {
        return new RulesApiClient();
    }
    get selfClient(): SelfAccountApiClient {
        return new SelfAccountApiClient();
    }
    get entryPointArgsClient(): EntryPointArgsApiClient {
        return new EntryPointArgsApiClient();
    }
}
